import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { DELAY } from '../../constants';
import HomeBanner from './homeBanner/homeBanner';
import * as Styled from './homeBannerListStyle';

const HomeBannerList = ({ homeBannerList = [] }) => {
  const delay = DELAY.homePromo;
  return (
    <Styled.HomeBannerContainer className="px-0">
      <Row className="g-0">
        {homeBannerList?.map((homeBanner, i) => (
          <Styled.HomeBannerCol key={i} className="px-0" xs={12} md={4}>
            <HomeBanner homeBanner={homeBanner} delay={delay * (i + 1)} />
          </Styled.HomeBannerCol>
        ))}
      </Row>
    </Styled.HomeBannerContainer>
  );
};

export default HomeBannerList;
