import React, { useEffect, useState } from 'react';
import * as Styled from './homeBannerStyle';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import IconOpenAccount from '../../../images/icon-open-account.png';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import useContentfulAssets from '../../../hooks/useContentfulAssets';
import { SECTIONS } from '../../../constants';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
} from '../../../utils';

const HomeBanner = ({ homeBanner, delay }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const homeBannerData = homeBanner?.listSection;
  const imageWithPathData = homeBannerData?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.IMAGE_WITH_PATH;
  })[0];
  const mobileData = homeBannerData?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.TEXT_WITH_PATH_FOR_MOBILE;
  })[0];
  const mobileCardlockData = homeBannerData?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.LINK_WITH_PDF;
  })[0];

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.HomeBannerBoxExploreInnerParagraph
          data-aos="fade-up"
          data-aos-delay={delay}
          data-aos-duration="1000"
          data-aos-once="true"
        >
          {children}
        </Styled.HomeBannerBoxExploreInnerParagraph>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  const mobileDataStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) =>
        node?.content?.find((node) =>
          node?.nodeType?.includes('asset-hyperlink')
        ) ? (
          <div>{children}</div>
        ) : (
          <p className="m-0">{children}</p>
        ),
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        if (asset) {
          return (
            <Styled.HomeBannerBoxAccount
              href={asset?.node?.file?.url}
              target="_blank"
              $scrollPosition={scrollPosition}
            >
              <Styled.HomeBannerBoxAccountInner>
                <Styled.HomeBannerBoxAccountInnerImage src={IconOpenAccount} />
                {mobileCardlockData?.header}
              </Styled.HomeBannerBoxAccountInner>
            </Styled.HomeBannerBoxAccount>
          );
        }
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  return (
    <Styled.HomeBannerBox>
      <Styled.HomeBannerBoxExplore
        $backgroundImage={imageWithPathData?.image?.file?.url}
        href={
          isExternalUrlHref(imageWithPathData?.path)
            ? imageWithPathData?.path
            : addTrailingSlash(
                process.env.GATSBY_DOMAIN_URL + imageWithPathData?.path
              )
        }
        target={isExternalUrl(imageWithPathData?.path) ? '_blank' : '_self'}
      >
        <Styled.HomeBannerBoxExploreInner>
          <Styled.HomeBannerBoxExploreInnerHeader
            data-aos="fade-up"
            data-aos-delay={delay}
            data-aos-duration="1000"
            data-aos-once="true"
          >
            {imageWithPathData?.title}
          </Styled.HomeBannerBoxExploreInnerHeader>
          {imageWithPathData?.richText?.raw &&
            documentToReactComponents(
              JSON.parse(imageWithPathData?.richText?.raw),
              optionsMainStyle
            )}
        </Styled.HomeBannerBoxExploreInner>
      </Styled.HomeBannerBoxExplore>
      {mobileData && (
        <Styled.HomeBannerBoxAccount
          href={
            isExternalUrlHref(mobileData?.path)
              ? mobileData?.path
              : addTrailingSlash(
                  process.env.GATSBY_DOMAIN_URL + mobileData?.path
                )
          }
          target={isExternalUrl(mobileData?.path) ? '_blank' : '_self'}
          $scrollPosition={scrollPosition}
        >
          <Styled.HomeBannerBoxAccountInner>
            <Styled.HomeBannerBoxAccountInnerImage src={IconOpenAccount} />
            {mobileData?.text}
          </Styled.HomeBannerBoxAccountInner>
        </Styled.HomeBannerBoxAccount>
      )}
      {mobileCardlockData?.contentDetails?.raw &&
        documentToReactComponents(
          JSON.parse(mobileCardlockData?.contentDetails?.raw),
          mobileDataStyle
        )}
    </Styled.HomeBannerBox>
  );
};

export default HomeBanner;
